{
  "name": "loan-renewal-fe",
  "version": "3.0.2-8c44d631.0",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build -c production",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.4",
    "@angular/common": "^19.0.4",
    "@angular/compiler": "^19.0.4",
    "@angular/core": "^19.0.4",
    "@angular/forms": "^19.0.4",
    "@angular/material": "^19.0.3",
    "@angular/platform-browser": "^19.0.4",
    "@angular/platform-browser-dynamic": "^19.0.4",
    "@angular/router": "^19.0.4",
    "rxjs": "~7.5.0",
    "tslib": "^2.0.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.5",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "^19.0.2",
    "@angular-eslint/schematics": "^19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/cdk": "^19.0.3",
    "@angular/cli": "^19.0.5",
    "@angular/compiler-cli": "^19.0.4",
    "@angular/material-luxon-adapter": "^19.0.3",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/renewal-tool": "^4.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.2",
    "@types/jasmine": "^4.0.3",
    "@types/loan-renewal-be": "^5.0.1",
    "@types/luxon": "^3.3.2",
    "@types/node": "^20",
    "@types/sparbanken-syd-auth-backend": "^2.0.0",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "eslint": "^8.53.0",
    "eslint-plugin-import": "2.27.5",
    "eslint-plugin-jsdoc": "^39.6.2",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "jasmine-core": "^5.0.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "^3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "karma-spec-reporter": "^0.0.36",
    "luxon": "^3.4.2",
    "postcss": "^8.4.21",
    "postcss-scss": "^4.0.6",
    "stylelint": "^16.1.0",
    "stylelint-config-standard-scss": "^13.0.0",
    "stylelint-scss": "^6.0.0",
    "typescript": "5.6.3",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.3/xlsx-0.20.3.tgz"
  }
}
